import React, {Component} from 'react'
import {base} from '../../base';
import '../../styles/css/CreatorAwards.css'
import ProgressButton from '../utils/ProgressButton'

class TriviaBigScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userAnswers: [],
            frozenVotingState: [],
            question: {},
            questionAnswered: false
        };
    }

    componentDidMount() {
        this.answerListRef = base.bindToState('userAnswers', {
            context: this,
            state: 'userAnswers',
            asArray: true,
        });
        this.questionTextRef = base.bindToState(`currentGame/question`, {
          context: this,
          state: 'question'
        });
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        });
        this.showPercentagesRef = base.bindToState('currentGame/showPercentages', {
            context: this,
            state: 'showPercentages',
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.answerListRef);
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.showPercentagesRef);
    }

    render() {
    const userAnswers = this.state.userAnswers || [];
    const questionAnswered = this.state.questionAnswered || false;
    const tenantVariables = this.props.variables || {};
    let totalTriviaAnswers = 0;
    const question = this.state.question;
    let cleanedAnswersArray = question.answers || [];
    let questionText = '4.3em';
    const image = tenantVariables.leftOfScoreboardImage;
    if(tenantVariables.questionsize && tenantVariables.questionsize.trim().length > 0){
      questionText = tenantVariables.questionsize + "px"
    }
    if(userAnswers.length > 0){
      totalTriviaAnswers = userAnswers.length;
      var tempCount = {};
      for(var answerIndex in userAnswers){
        var answerId = userAnswers[answerIndex].answerId;
        if(tempCount[answerId]){
          tempCount[answerId]['count'] = tempCount[answerId]['count'] + 1;
        } else {
          tempCount[answerId] = {};
          tempCount[answerId]['count'] = 1;
          tempCount[answerId]['answerText'] = userAnswers[answerIndex].answerText;
        }
      }
      let totalCurrentPercent = 0;
      for(const add_answer in cleanedAnswersArray){
        if(tempCount[cleanedAnswersArray[add_answer].id]){
          cleanedAnswersArray[add_answer]['count'] = tempCount[cleanedAnswersArray[add_answer].id].count;
          const percentToFill = tempCount[cleanedAnswersArray[add_answer].id].count/totalTriviaAnswers*100;
          cleanedAnswersArray[add_answer]['percentFilled'] = Math.floor(percentToFill);
          cleanedAnswersArray[add_answer]['remainder'] = percentToFill - Math.floor(percentToFill);
          totalCurrentPercent+=Math.floor(percentToFill);
        } else {
          cleanedAnswersArray[add_answer]['count'] = 0;
          cleanedAnswersArray[add_answer]['percentFilled'] = 0;
          cleanedAnswersArray[add_answer]['remainder'] = 0;
        }
      }
      let diff = 100 - totalCurrentPercent;
      let startIndex = 0;
      let duplicateCleanedAnswers = cleanedAnswersArray.slice(0);
      while(diff > 0 && totalCurrentPercent){
          duplicateCleanedAnswers.sort((a,b)=>b.remainder - a.remainder);
          for(const cleanedAnswersArrayIndex in cleanedAnswersArray){
              if(cleanedAnswersArray[cleanedAnswersArrayIndex].id === duplicateCleanedAnswers[startIndex].id){
                  cleanedAnswersArray[cleanedAnswersArrayIndex].percentFilled+=1;
              }
          }
          diff--;
          startIndex++;
      }
    }
    return (
        <div className="grid-wrapper" style={{backgroundImage: "url(" + tenantVariables.scoreboardBackgroundImage + ")"}}>
            <div className="grid-container-outer">
                <div className="grid-left">
                    <div className="creator-awards-logo"><img src={image} alt=""/></div>
                </div>
                <div>
                  <h1 style={{color:tenantVariables.progressBarQuestionTextColor, marginBottom:"30px", fontSize:questionText, fontWeight:700}}>{question.questionText}</h1>
                  {
                      cleanedAnswersArray.map(function(item,i){
                        let amountFilled = 0;
                        if(totalTriviaAnswers > 0){
                          amountFilled = item.percentFilled;
                        }
                        if(questionAnswered && item.correct){
                            return <div key={i} style={{overflow:"auto", marginBottom: 10}}>
                                  <div style={{float:"left", marginRight:10}}>
                                    <img src={item.answerText} alt=""/>
                                  </div>
                                  <div className="item-scoreboard" style={{overflow:"auto", paddingRight:10}}>
                                      <ProgressButton variables={tenantVariables} title={item.answerText} answered={this.state.answered} progress={amountFilled} correct={true}/>
                                  </div>
                                </div>
                          } else {
                            return <div key={i} style={{overflow:"auto", marginBottom: 10}}>
                              <div style={{float:"left", marginRight:10}}>
                                <img src={item.answerText} alt=""/>
                              </div>

                              <div className="item-scoreboard" style={{overflow:"auto", paddingRight:10}}>
                                <ProgressButton variables={tenantVariables} title={item.answerText} answered={this.state.answered} progress={amountFilled}/>
                              </div>
                            </div>
                          }
                      }, this)
                    }
                  </div>
            </div>
        </div>
      )
    }
}

export default TriviaBigScreen;
